import isLoggedIn from './isLoggedIn'
import hasCheckoutId from './hasCheckoutId'
import { middlewareInstructions, middlewareContext } from './types'
import isAdmin from './isAdmin'
import isCustomer from './isCustomer'
import isPartner from './isPartner'
import isLoggedOut from './isLoggedOut'
import redirect from './restrictAll'
import isAdminOrPartner from '~/middleware/isAdminOrPartner'
import isPendingPartner from './isPendingPartner'

export default class Middleware {
  middleware = {
    isLoggedIn,
    isLoggedOut,
    hasCheckoutId,
    isAdmin,
    isAdminOrPartner,
    isPartner,
    isPendingPartner,
    isCustomer,
    redirect,
  }

  /**
   * Pay attention to middlewareInstructions type: use 'actions' property to logout user;
   * Logging out user inside the middleware, with direct logout() calls, is not recommended, because it may cause
   * infinite loop.
   */

  checkMiddleware(
    middleWareNames: string[] | string,
    ctx: middlewareContext,
  ): middlewareInstructions {
    const array = Array.isArray(middleWareNames)
      ? middleWareNames
      : [middleWareNames]
    let returnPage = { to: { name: '' } }

    for (let i = 0; i < array.length; i++) {
      const key = array[i]
      if (!(key in this.middleware)) continue

      // @ts-ignore
      const result = this.middleware[key](ctx)
      if (result?.to?.name !== '') {
        returnPage = result
        break
      }
    }
    return returnPage
  }
}
