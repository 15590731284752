/* eslint-disable no-unused-vars */
import { SearchParams } from './pagination'
import { InviteMember, TeamMemberStatus } from './members'
import { Role } from '~/types/users'

export type Partner = {
  id: string
  name: string
  phoneNumber?: string
  city?: string
  country?: string
  email?: string
  postalCode?: string
  salesForceCustomerId?: string
  state?: string
  status?: PartnerStatus
  street?: string
  invoiceEmail?: string
  vatNumber?: string
  tier?: PartnerTier
  billingCity?: string
  billingCountry?: string
  billingName?: string
  billingPostalCode?: string
  billingState?: string
  billingStreet?: string
  billingVatNumber?: string
  netTermDayOptions?: PartnerNetTermDays
}

export type PartnersResponse = {
  data: Partner[]
  pages: number
  total: number
}

export enum PartnerNetTermDays {
  NET_15 = 'NET_15',
  NET_30 = 'NET_30',
  NET_45 = 'NET_45',
  NET_60 = 'NET_60',
}

export enum PartnerTier {
  SOLUTIONS = 'SOLUTIONS',
  BUSINESS = 'BUSINESS',
  AUTHORIZED = 'AUTHORIZED',
  AFFILIATE = 'AFFILIATE',
  GLOBAL_ALLIANCE = 'GLOBAL_ALLIANCE',
  DISTRIBUTOR = 'DISTRIBUTOR',
}

export type UserInvitePayload = {
  invites: InviteMember[]
}

export enum PartnerStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum InvoiceStatus {
  ALL = 'ALL',
  PAID = 'PAID',
  POSTED = 'POSTED',
  PAYMENT_DUE = 'PAYMENT_DUE',
  NOT_PAID = 'NOT_PAID',
  VOIDED = 'VOIDED',
  PENDING = 'PENDING',
}

export interface PartnersSearchParams extends SearchParams {
  country?: string
  status?: string
  name?: string
}

export type PartnerUser = {
  id: string
  email: string
  firstName: string
  lastName: string
  role: Role
  status: TeamMemberStatus
  userId: string
}

export type GetPartnerUsersResponse = {
  data: PartnerUser[]
  pages: number
  total: number
}

export type PaymentSource = {
  id: string
  type: string
  details: Record<string, string>
}

export type InvitePartnerResponse = {
  partnerId: string
}

export type InvitedUser = {
  userPartnerId: string
}

export type InviteUserResponse = InvitedUser[]
