import { Role } from '~/types/users'
import { userIs, userHasNoRoles } from './helpers'
import { middlewareInstructions } from './types'

export default function isPartner(): middlewareInstructions {
  if (userHasNoRoles()) {
    return { to: { name: 'subscription-portal-sign-in' }, actions: 'logout' }
  }
  if (userIs(Role.PARTNER)) return { to: { name: '' } }
  return userIs(Role.SUPER_ADMIN)
    ? { to: { name: 'subscription-portal-partners' } }
    : { to: { name: 'subscription-portal-customers' } }
}
