export type User = {
  id: string
  email: string
  roles?: string[]
  firstName?: string
  lastName?: string
}

export enum Role {
  SUPER_ADMIN = 'SUPER_ADMIN',
  PARTNER = 'PARTNER',
  CUSTOMER = 'CUSTOMER',
}
