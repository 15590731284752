import { KyInstance } from 'ky/distribution/types/ky'
import ApiAuth from './auth'
import ApiBraintree from './braintree'
import ApiCheckout from './checkout'
import ApiCustomers from './customers'
import ApiCountries from './countries'
import ApiUsers from './users'
import ApiPartners from './partners'
import ApiSubscriptions from './subscriptions'
import ApiUrlPrefix from './url-prefix'
import ApiPrices from './prices'
import ApiVat from './vat'
import ApiVersion from './version'
import ApiInvoices from './invoices'
import ApiCreditNotes from './credit-notes'
import ApiAdmin from './admin'

export interface Api extends KyInstance {
  auth: ApiAuth
  braintree: ApiBraintree
  checkout: ApiCheckout
  customers: ApiCustomers
  countries: ApiCountries
  users: ApiUsers
  partners: ApiPartners
  subscriptions: ApiSubscriptions
  urlPrefix: ApiUrlPrefix
  prices: ApiPrices
  vat: ApiVat
  version: ApiVersion
  invoices: ApiInvoices
  creditNotes: ApiCreditNotes
  admin: ApiAdmin
}

export class ApiKy {
  ky: KyInstance
  url: string

  constructor(api: KyInstance, url: string) {
    this.ky = api
    this.url = url
  }
}
