import { Role } from '~/types/users'
import { userIs, userHasNoRoles } from './helpers'
import { middlewareInstructions } from './types'

export default function isAdminOrPartner(): middlewareInstructions {
  if (userHasNoRoles()) {
    return { to: { name: 'subscription-portal-sign-in' }, actions: 'logout' }
  }
  if (userIs(Role.SUPER_ADMIN) || userIs(Role.PARTNER))
    return { to: { name: '' } }
  return { to: { name: 'subscription-portal-subscriptions' } }
}
